.success-icon {
	svg {
		color: #3fa358 !important;
	}
}

.error-icon {
	svg {
		color: #da1e28 !important;
	}
}

.warning-icon {
	svg {
		color: #f1c21b !important;
	}
}

.status-icon {
	background-color: transparent;
	border-color: transparent;
	margin-top: -1rem;

	.bx--assistive-text {
		top: 75% !important;
		left: 250% !important;
		margin-left: 0.5rem !important;
	}
}

.status-icon:hover {
	background-color: transparent;
	border-color: transparent;
}

.list {
	display: flex;
	margin-left: 3rem;
	margin-top: 3rem;
	margin-right: 9rem;
	align-items: center;
	justify-content: space-between;
	align-content: flex-start;
	height: 85vh;
}

.results-margin {
	margin-right: 3rem;
}
