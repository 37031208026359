.bx--header {
    position: static;
    background-color: #2e3191;
    width: 100vw;
}

.bx--header__action {
    svg:hover {
        fill: #ed8045 !important;
    }
}

.bx--header__menu-item:hover {
    background-color: inherit !important;
    color: #ed8045 !important;
}

.bx--header__action:hover {
    background-color: inherit !important;
}

.header-component {
    z-index: 1;
}

a.bx--header__menu-item {
    color: #f4f4f4;
    font-family: "IBM PLEX SANS";
}