.results-download-icon {
    background-color: transparent;
    border-color: transparent;
    color: black;
}

.results-download-icon:hover {
    background-color: #f4f4f4;
    color: #ed8045;
}

.no-outline:focus {
    outline: 0;
}

.no-outline:focus {
    outline: 0;
}

.bx--overflow-menu-options--open {
    top: 232.017px;
    position: absolute;
    margin: 0px;
    opacity: 1;
    left: 1393.49px;
    right: auto;
    width: 14rem;
}

.bx--overflow-menu-options__option {
    display: flex;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    padding: 0;
    background-color: transparent;
    transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
    white-space: nowrap;
}

.bx--batch-actions {
    // background-color: #ee8b34;
    background-color: #2e3191;

    .bx--btn--primary {
        padding-left: 1rem;
        border: none;
        // outline: #ee8b34;
        outline: #2e3191;
        background-color: #2e3191;
        // background-color: #ee8b34;
        box-shadow: none;
        &:hover {
            background-color: #2e3191;
            box-shadow: none;
            color: #ed8045;
        }
    }
}
