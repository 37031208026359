.card-container {
    width: 100%;
    height: 100%;
    display: flex;

    .base-fields-area {
        padding-left: 50px;
        border: 0px;
        z-index: 1;
        min-width: 400px;
    }

    .fields-area {
        margin-top: 4rem;
        width: 40%;
        @extend .base-fields-area;
    }

    .login-title {
        font-size: xx-large;
        line-height: 1.34;
        letter-spacing: 0.32px;
        display: inline-block;
        font-weight: 400;
        line-height: 1rem;
        vertical-align: baseline;
    }

    .login-image {
        // justify-content: flex-start;
        // height: 100%;
        // width: 100%;
        // object-fit: cover;
        // object-position: left bottom;
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // z-index: 0;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: right top;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .bx--form {


        .login-title {
            margin-bottom: 2rem;
        }

        .input {
            width: 100%;
            margin-bottom: 2rem;
        }

        .bx--text-input__field-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
        }

        .bx--btn {
            display: flex;
            width: 100%;
            padding: 0px;
            min-width: none;
            max-width: none;
        }

        .login-button-arrow {
            margin: 1rem;
        }

        .bx--btn--primary {
            padding-left: 1rem;
            border: none;
            outline: #ed8045;
            background-color: #ed8045;
            box-shadow: none;
            &:hover {
                // background-color: #e96f03;
				color: #2e3191;
                box-shadow: none;
            }
        }

        .inline-notification {
            width: 100% !important;
            margin-bottom: 2rem;
        }

        .fade-in-1 {
            animation: 1s slideFromLeft, 1s fadeIn;
            opacity: 1;
        }

        .login-app-version {
            position: fixed;
            bottom: 0;
            right: 30px;
            font-style: italic;
            font-size: 12px;
        }
    }
}


.blue-text {
    color: #2e3191;
}

.blue-text:hover {
    color: #ed8045;
}

.base-app-version {
    position: fixed;
    bottom: 0.5rem;
    font-style: italic;
    font-size: 12px;
    @extend .blue-text;
}

.login-app-version {
    @extend .base-app-version;
}

@media only screen and (max-width: 1024px) {
    .fields-area {
        width: 80% !important;
        margin-top: 1rem !important;
    }

    .login-app-version {
        right: 3rem;
    }
}