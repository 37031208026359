.button-design {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    transition: all 0.11s;
    padding-top: 0.325rem;
    border-color: transparent;
    background: black;

    svg {
        height: 70%;
        width: 70%;
        align-items: center;
    }
}

.add-buttons-div {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger {
    position: relative;
    display: inline-flex;
    align-items: center;
    overflow: visible;
    cursor: pointer;
    align-content: center;
    justify-content: center;
}