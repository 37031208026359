.file-add-modal {
    .bx--btn--primary {
        background-color: #ed8045;
        color: #ffffff;
        box-shadow: none;
        border: none;

        &:hover {
            color: #2e3191;
            box-shadow: none;
            border: none;
        }
    }

    .bx--btn--secondary {
        background-color: #2e3191;
        color: #ffffff;
        box-shadow: none;
        border: none;

        &:hover {
            color: #ed8045;
            box-shadow: none;
            border: none;
        }
    }

    .bx--modal-container {
        background-color: white;
    }

    .bx--modal-container--sm .bx--modal-content {
        padding: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .bx--file {
        width: auto;
    }

    .bx--file__drop-container {
        min-width: 18rem;
        border: 1px dashed #2e3191;
    }
    .bx--file-browse-btn {
		color: #2e3191;
        &:hover {
            color: #ed8045;
            outline: 2px solid #ed8045;
        }
    }
    .file-modal-text {
        margin-bottom: 1rem;
        font-weight: bold;
    }
}
