.search-result-list {
	margin-top: 3rem;
	align-items: center;
	justify-content: space-between;
	align-content: flex-start;
	height: 85vh;
}

.search-highlight {
	font-weight: 600;
    background-color: yellow;
}