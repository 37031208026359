.bx--table-sort__flex {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.pagination-size {
    height: 4rem;
    width: 100%;
}

.bx--data-table-container {
    padding-top: 0.125rem;
    width: 100%;
    height: 100%;
}

.bx--loading-overlay {
    position: relative;
    z-index: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 22px;
    height: 22px;
    max-width: 22px;
    background-color: transparent;
    transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1);
    overflow: hidden;
    left: 0.75rem;
}

.bx--loading {
    animation-name: rotate;
    animation-duration: 3000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    width: 22px;
    height: 22px;
    overflow: hidden;
}

.bx--loading__stroke {
    stroke: #f58220;
    stroke-width: 15px !important;
    stroke-dashoffset: 40;
}

.bx--data-table_inner-container {
    background-color: #e5e5e5;
    height: 75%;
}
.bx--data-table-content {
    width: 100%;
    height: 100%;
}

.bx--data-table--sticky-header {
    display: block;
    max-height: 100%;
    min-height: 100%;
    width: 100%;
}

.bx--data-table--sticky-header tbody {
    height: 100%;
}

.bx--table-toolbar {
    position: relative;
    display: flex;
    width: 100%;
    height: 3rem;
    overflow: visible;
    background: #f4f4f4;
    z-index: 3;
}

.bx--toast-notification--low-contrast.bx--toast-notification--info {
    background: #f4f4f4;
    border-left: none;
}

.bx--toast-notification__icon {
    visibility: hidden;
}

.bx--toast-notification__details {
    margin-right: 1rem;
    margin-left: -2rem;
}

.notification-checkbox-div {
    display: flex;
    position: fixed;
    margin-top: 2rem;
    margin-right: 5rem;
}

.results-filter-checkbox {
    margin-bottom: 1rem;
}

.bx--checkbox:checked + .bx--checkbox-label::before {
    background-color: #2e3191;
    border-color: #2e3191;
    border-width: 0px;


}

.bx--checkbox:focus{
	border-color: red;


}

